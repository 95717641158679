import React from 'react';

const ProjectItem = ({ src, title, text, tags, linkLive, linkGitHub }) => {
    const tagItems = tags.map((tag) =>
        <li key={tag.id}>{tag}</li>);

    return (
        <div className='project'>
            <div className="project-img">
                <img src={src} alt={`${title}`} />
            </div>
            <div className="project-content-container">
                <h3>{title}</h3>
                <div className="project-content">
                    <p>{text}</p>
                </div>
                <ul className="project-tags">
                    {tagItems}
                </ul>
                <div className="project-links">
                    <a className='btn btn-outline' target='blank' rel='noopener' href={linkLive}>
                        Live Website
                    </a>
                    <a className='btn btn-outline' target='blank' rel='noopener' href={linkGitHub}>
                        GitHub Repository
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ProjectItem
