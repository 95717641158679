import React, { useState } from 'react';
import './Navbar.css';

function Navbar(props) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const scrollUp = (e) => {
        window.scrollTo(0, 0)
    };

    return (
        <>
            <nav className="navbar" id='navbar'>
                <div className="navbar-container">
                    <a href='/' className='navbar-logo' onClick={scrollUp}>
                        <div>
                            <p class="heading">TS</p>
                        </div>
                    </a>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <a href='/#about' className='nav-links' onClick={closeMobileMenu}>About Me</a>
                        </li>
                        <li className='nav-item'>
                            <a href="/#projects" className='nav-links' onClick={closeMobileMenu}>Projects</a>
                        </li>
                        <li className='nav-item'>
                            <a href='/#skills' className='nav-links' onClick={closeMobileMenu}>Skills</a>
                        </li>
                        <li className='nav-item'>
                            <a href='/#work' className='nav-links' onClick={closeMobileMenu}>Work & Education</a>
                        </li>
                        <li className='nav-item'>
                            <a href="#contact" className='nav-links' onClick={closeMobileMenu}>Contact</a>
                        </li>
                    </ul>
                    <select
                        className="custom-select"
                        value={props.language}
                        onChange={e => props.handleSetLanguage(e.target.value)}
                    >
                        <option value="nederlands">Nederlands</option>
                        <option value="english">English</option>
                    </select>
                </div>
            </nav>
        </>
    )
}

export default Navbar
