import React from 'react';
import './Projects.css';
import ProjectItem from './ProjectItem';

function ProjectsNL() {
    return (
        <div className='projects' id='projects'>
            <h1>Mijn projecten</h1>
            <p>Als onderdeel van de opleiding heb ik vier websites gebouwd met toenemende moeilijkheidsgraad en gebruik makend
                van verschillende programmeertalen.
            </p>
            <ProjectItem
                src='images/besharp.png'
                title='Be Sharp'
                text='Een fictieve website voor vocal group Be Sharp.'
                tags={['HTML', 'CSS', 'Bootstrap']}
                linkLive='https://chizzletaz.github.io/MilestoneProject1/'
                linkGitHub='https://github.com/chizzletaz/MilestoneProject1'
            />
            <ProjectItem
                src='images/triviata.png'
                title='Triviata'
                text='Een leuk en interactief trivia spelletje.'
                tags={['HTML', 'CSS', 'Bootstrap', 'Javascript']}
                linkLive='https://chizzletaz.github.io/Triviata/'
                linkGitHub='https://github.com/chizzletaz/Triviata'
            />
            <ProjectItem
                src='images/bake-and-binge.jpg'
                title='Bake and Binge'
                text='Een bakrecepten website, waar gebruikers een account kunnen aanmaken en vervolgens hun eigen recepten kunnen toevoegen, bewerken en verwijderen.'
                tags={['Python', 'Flask', 'MongoDB']}
                linkLive='https://bake-and-binge.herokuapp.com'
                linkGitHub='https://github.com/chizzletaz/BakeAndBinge'
            />
            <ProjectItem
                src='images/space-travel-agency.png'
                title='Space Travel Agency'
                text='Een volledig werkende e-commerce web applicatie voor een fictief ruimtereis bedrijf dat ruimtereizen aanbiedt.'
                tags={['Python', 'Django', 'Stripe', 'AWS']}
                linkLive='https://space-travel-agency.onrender.com'
                linkGitHub='https://github.com/chizzletaz/SpaceTravelAgency'
            />
            <ProjectItem
                src='images/cocktail.png'
                title='Cocktail Website'
                text='Een cocktail website gemaakt als een oefening voor javascript en API functionaliteit.'
                tags={['HTML', 'CSS', 'Bootstrap', 'Javascript']}
                linkLive='https://chizzletaz.github.io/cocktail/'
                linkGitHub='https://github.com/chizzletaz/cocktail'
            />
        </div>
    )
}

export default ProjectsNL
