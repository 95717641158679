import React from 'react';
import './Work.css';

const Work = () => {
    return (
        <div id='work-wrapper'>
            <div id="work">
                <h1>Work & Education</h1>
                <div className="history-items">
                    <div className="timeline-item" data-dates="2022 - present">
                        <h4>Traineeship Java Software Developer</h4>
                        <p>Currently I'm following the traineeship to become a Java Developer at <a className='internalLink' href='https://www.ilionx.com'>ilionx</a> in Zwolle. During the first two months of
                            the traineeship I was educated in Java and related subjects. After that I started working in my first team. We are working on a few different projects for different companies.
                        </p>
                        <p>The traineeship lasts for one year. During this year you are working more and more indepently, but still get coaching.</p>
                    </div>
                    <div className="timeline-item" data-dates="2020 - 2021">
                        <h4>Full Stack Web Development Bootcamp</h4>
                        <p>Code Institute is an online coding bootcamp for a FullStack Software Development diploma. The Full Stack Software Development
                            curriculum covers several coding languages and frameworks used in Frontend and Backend,
                            such as HTML, CSS, JavaScript, Python, Flask, MongoDB, Django and competencies such as agile development en project management.
                            There are <a className='internalLink' href='#projects'>4 Milestone Projects</a>, where the new skills are applied.</p>
                    </div>
                    <div className="timeline-item" data-dates="2008 - present">
                        <h4>Musik Akademie Obergrafschaft</h4>
                        <p>Teaching piano and keyboard at the music school in Bad Bentheim, Schüttorf and Gildehaus
                            (Germany).</p>
                    </div>
                    <div className="timeline-item" data-dates="2007 - present">
                        <h4>MuziTaz</h4>
                        <p>Started a sole proprietorship named <span>Tasco Silva musicus</span>, renamed to <span>MuziTaz</span> in 2019. Under
                            this name I do my freelance work (private teaching ,
                            working with various choirs as rehearsal pianist, musical director, conductor, vocal coach and
                            collaborating on projects).</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work
