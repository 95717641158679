import React from 'react';
import './Hero.css';

const Hero = () => {
    return (
        <div id="hero">
            <div class="personal">
                <div>
                    <div>
                        <h1 className='rotate'>Tasco</h1>
                        <h1>Silva</h1>
                    </div>
                    <div id='avatar' >
                        <img src='images/Me.jpg' alt='profile'></img>
                    </div>
                </div>
                <div id="personal-text">
                    <h2>Not only a musician,</h2>
                    <h2>but also a proud junior</h2>
                    <h2>Java software developer.</h2>
                </div>
            </div>
        </div>
    )
}

export default Hero
