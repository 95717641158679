import React from "react";
import IMAGES from "./images";

const SkillsNL = () => {
  return (
    <div id="skills">
      <h1>Mijn Skills</h1>
      <div className="skills-container">
        <div className="front">
          <div className="icon">
            <i class="fas fa-laptop-code"></i>
            <h2>Front End</h2>
          </div>
          <div className="front-content">
            <div className="skill-logos">
              <img src={IMAGES.HTML5} alt="html logo" />
              <img src={IMAGES.CSS} alt="css logo" />
              <img src={IMAGES.Bootstrap} alt="bootstrap logo" />
              <img src={IMAGES.Javascript} alt="javascript logo" />
              <img src={IMAGES.React} alt="react logo" />
            </div>
            <p>
              Ik ben bekwaam in User Experience(UX) Design waarbij gebruik gemaakt wordt van HTML, CSS en
              Javascript/jQuery. Ik heb gewerkt met de Bootstrap en Materialize CSS frameworks.
            </p>
            <p>
              Mijn laatste skill is React, een Javascript Framework. Deze website is gemaakt met
              React.
            </p>
          </div>
        </div>
        <div className="back">
          <div className="icon">
            <i class="fas fa-cogs"></i>
            <h2>Back End</h2>
          </div>
          <div className="back-content">
            <div className="skill-logos">
              <img src={IMAGES.Java} alt="java logo" />
              <img src={IMAGES.Python} alt="python logo" />
              <img src={IMAGES.Flask} alt="flask logo" />
              <img src={IMAGES.MongoDB} alt="mongodb logo" />
              <img src={IMAGES.Heroku} alt="heroku logo" />
              <img src={IMAGES.Django} alt="django logo" />
            </div>
            <p>
              Ik heb junior level kennis van Java. Dit is de programmeertaal waar ik op dit moment mee werk.
            </p>
            <p>
              Ik beschik over een basiskennis van Back End development. Ik heb Python
              en het Flask framework gebruikt, samen met een MongoDB database om een bakrecepten website te creëren.
            </p>
            <p>
              In mijn e-commerce project heb ik Python en het Django framework gebruikt, samen met
              PostgeSQL als database om een e-commerce website te creëren voor een reisbureau, gespecialiseerd in ruimtereizen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsNL;
