import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

export default function Contact() {
    const [message, setMessage] = useState(false)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_09m7e8t', 'portfolio', form.current, 'user_c1vV0cUQUiGgBcIhgG9ok')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        //   resetting the form after submitting
        e.target.reset();
        setMessage(true);
    };


    return (
        <div id='contact'>
            <h1>Contact Me</h1>
            <div className="form-container">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" className='contactform' placeholder='First name' name='firstname'
                        aria-label='First name' required/>
                    <input type="text" className='contactform' placeholder='Last name' name='lastname'
                        aria-label='Last name' required/>
                    <input type="email" className='contactform' placeholder='Email address' name='email'
                        aria-label='Email address' required/>
                    <textarea id="contactFormTextarea"
                        placeholder='Your message' name='message'></textarea>
                    <input type="submit" value="Submit" className='btn btn-outline-dark' required/>
                    {message && <span>Thank you for your message. I'll get back to you asap.</span>}
                </form>

            </div>
        </div>
    )
}
