import React, { useState } from 'react';
import Navbar from './components/Navbar';
import NavbarNL from './components/NavbarNL';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Projects from './components/Projects';
import ProjectsNL from './components/ProjectsNL';
import About from './components/About';
import AboutNL from './components/AboutNL';
import Skills from './components/Skills';
import SkillsNL from './components/SkillsNL';
import Contact from './components/Contact';
import ContactNL from './components/ContactNL';
import Work from './components/Work';
import WorkNL from './components/WorkNL';
import Footer from './components/Footer';
import Hero from './components/Hero';
import HeroNL from './components/HeroNL';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );

  if (language === 'english') {
    return (
      <>
        <Router>
          <Navbar
            language={language}
            handleSetLanguage={language => {
              setLanguage(language);
              storeLanguageInLocalStorage(language);
            }} />
          <Hero />
          <About />
          <Projects />
          <Work />
          <Skills />
          <Contact />
          <Footer />
          <ScrollToTopButton />
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          <NavbarNL
            language={language}
            handleSetLanguage={language => {
              setLanguage(language);
              storeLanguageInLocalStorage(language);
            }} />
          <HeroNL />
          <AboutNL />
          <ProjectsNL />
          <WorkNL />
          <SkillsNL />
          <ContactNL />
          <Footer />
          <ScrollToTopButton />
        </Router>
      </>
    );
  }

}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

export default App;
