import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

export default function ContactNL() {
    const [message, setMessage] = useState(false)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_09m7e8t', 'portfolio', form.current, 'user_c1vV0cUQUiGgBcIhgG9ok')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        //   resetting the form after submitting
        e.target.reset();
        setMessage(true);
    };


    return (
        <div id='contact'>
            <h1>Contact</h1>
            <div className="form-container">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" className='contactform' placeholder='Voornaam' name='firstname'
                        aria-label='First name' />
                    <input type="text" className='contactform' placeholder='Achternaam' name='lastname'
                        aria-label='Last name' />
                    <input type="email" className='contactform' placeholder='E-mailadres' name='email'
                        aria-label='Email address' />
                    <textarea id="contactFormTextarea"
                        placeholder='Uw bericht' name='message'></textarea>
                    <input type="submit" value="Verzend" className='btn btn-outline-dark' />
                    {message && <span>Bedankt voor uw bericht. Ik neem z.s.m contact met u op.</span>}
                </form>

            </div>
        </div>
    )
}
