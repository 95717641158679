import React from 'react';
import './Projects.css';
import ProjectItem from './ProjectItem';

function Projects() {
    return (
        <div id='projects'>
            <h1>My projects</h1>
            <p>
                As part of the bootcamp I've made 4 major websites with increasing difficulty and use of coding languages.
            </p>
            <ProjectItem
                src='images/besharp.png'
                title='Be Sharp'
                text='A fictional website for vocal group Be Sharp.'
                tags={['HTML', 'CSS', 'Bootstrap']}
                linkLive='https://chizzletaz.github.io/MilestoneProject1/'
                linkGitHub='https://github.com/chizzletaz/MilestoneProject1'
            />
            <ProjectItem
                src='images/triviata.png'
                title='Triviata'
                text='A fun interactive trivia game.'
                tags={['HTML', 'CSS', 'Bootstrap', 'Javascript']}
                linkLive='https://chizzletaz.github.io/Triviata/'
                linkGitHub='https://github.com/chizzletaz/Triviata'
            />
            <ProjectItem
                src='images/bake-and-binge.jpg'
                title='Bake and Binge'
                text='A baking recipe website, where users can create an account and add, edit and delete their own recipes.'
                tags={['Python', 'Flask', 'MongoDB']}
                linkLive='https://bake-and-binge.herokuapp.com'
                linkGitHub='https://github.com/chizzletaz/BakeAndBinge'
            />
            <ProjectItem
                src='images/space-travel-agency.png'
                title='Space Travel Agency'
                text='A fully functional e-commerce web application for a fictitious space
                    travel company that offer trips to outer space.'
                tags={['Python', 'Django', 'Stripe', 'AWS']}
                linkLive='https://space-travel-agency.onrender.com'
                linkGitHub='https://github.com/chizzletaz/SpaceTravelAgency'
            />
            <ProjectItem
                src='images/cocktail.png'
                title='Cocktail Website'
                text='A cocktail website made as an exercise for javascript and API functionality.'
                tags={['HTML', 'CSS', 'Bootstrap', 'Javascript']}
                linkLive='https://chizzletaz.github.io/cocktail/'
                linkGitHub='https://github.com/chizzletaz/cocktail'
            />
        </div>
    )
}

export default Projects
