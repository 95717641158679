import React from "react";
import IMAGES from "./images";

const Skills = () => {
  return (
    <div id="skills">
      <h1>My Skills</h1>
      <div className="skills-container">
        <div className="front">
          <div className="icon">
            <i class="fas fa-laptop-code"></i>
            <h2>Front End</h2>
          </div>
          <div className="front-content">
            <div className="skill-logos">
              <img src={IMAGES.HTML5} alt="html logo" />
              <img src={IMAGES.CSS} alt="css logo" />
              <img src={IMAGES.Bootstrap} alt="bootstrap logo" />
              <img src={IMAGES.Javascript} alt="javascript logo" />
              <img src={IMAGES.React} alt="react logo" />
            </div>
            <p>
              I'm skilled in User Experience(UX) Design using HTML, CSS and
              Javascript/jQuery. I've worked with the Bootstrap and Materialize
              framework.
            </p>
            <p>
              My latest skill is learning React, a Javascript Framework. This website was made with
              React.
            </p>
          </div>
        </div>
        <div className="back">
          <div className="icon">
            <i class="fas fa-cogs"></i>
            <h2>Back End</h2>
          </div>
          <div className="back-content">
            <div className="skill-logos">
              <img src={IMAGES.Java} alt="java logo" />
              <img src={IMAGES.Python} alt="python logo" />
              <img src={IMAGES.Flask} alt="flask logo" />
              <img src={IMAGES.MongoDB} alt="mongodb logo" />
              <img src={IMAGES.Heroku} alt="heroku logo" />
              <img src={IMAGES.Django} alt="django logo" />
            </div>
            <p>
              I have junior level knowledge of Java, which is the programming language I'm working with at
              the moment.
            </p>
            <p>
              Furthermore I have a basic knowledge of Back End development. I've used Python
              and the Flask framework in conjunction with MongoDB to create a baking recipe website.
            </p>
            <p>
              In my e-commerce project, I've used Python and the Django framework with
              PostgeSQL as a database to create a space travel e-commerce website.
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Skills;
