import React from 'react';
import './Work.css';

const WorkNL = () => {
    return (
        <div id='work-wrapper'>
            <div id="work">
                <h1>Werk & Opleiding</h1>
                <div className="history-items">
                    <div className="timeline-item" data-dates="2022 - present">
                        <h4>Traineeship Java Software Developer</h4>
                        <p>Op dit moment volg ik het traineeship om een Java Developer te worden bij <a className='internalLink' href='https://www.ilionx.com'>ilionx</a> in Zwolle.
                            Gedurende de eerste twee maanden van dit traineeship krijg je een opleiding in Java en gerelateerde onderwerpen.
                            Daarna ben ik begonnen te werken in mijn eerste team. We werken aan een paar verschillende projecten van verschillende bedrijven.
                        </p>
                        <p>Het traineeship duurt een jaar. Tijdens dit jaar ga je steeds meer zelfstandiger werken, maar is er nog wel begeleiding.</p>

                    </div>
                    <div className="timeline-item" data-dates="2020 - 2021">
                        <h4>Full Stack Web Development Bootcamp</h4>
                        <p>Code Institute is een online coding bootcamp waarmee het FullStack Software Development diploma kan worden behaald.
                            Het Full Stack Software Development
                            curriculum behandelt verschillende programmeertalen en frameworks die gebruikt worden in frontend en backend,
                            zoals HTML, CSS, JavaScript, Python, Flask, MongoDB, Django en vaardigheden zoals agile development en project management.
                            Er zijn <a className='internalLink' href='#projects'>4 Milestone projecten</a>, waarin de nieuwe kennis en vaardigheden worden toegepast.</p>
                    </div>
                    <div className="timeline-item" data-dates="2008 - heden">
                        <h4>Musik Akademie Obergrafschaft</h4>
                        <p>Piano- en keyboardles geven aan de muziekschool in Bad Bentheim, Schüttorf en Gildehaus
                            (Duitsland).</p>
                    </div>
                    <div className="timeline-item" data-dates="2007 - heden">
                        <h4>MuziTaz</h4>
                        <p>Begonnen als ZZP'er onder de naam <span>Tasco Silva musicus</span>, hernoemt naar <span>MuziTaz</span> in 2019. Onder
                            deze naam doe ik mijn freelance work (prive lesgeven, werken met verschillende koren als repetitor, muzikaal leider,
                            dirigent, vocal coach en medewerking verlenen aan diverse projecten).</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkNL
