import React from 'react';
import './Hero.css';

const HeroNL = () => {
    return (
        <div id="hero">
            <div class="personal">
                <div>
                    <div>
                        <h1 className='rotate'>Tasco</h1>
                        <h1>Silva</h1>
                    </div>
                    <div id='avatar' >
                        <img src='images/Me.jpg' alt='profile'></img>
                    </div>
                </div>
                <div id="personal-text" >
                    <h2>Niet alleen musicus,</h2>
                    <h2>maar ook een trotse junior</h2>
                    <h2>Java software developer.</h2>
                </div>
            </div>
        </div>
    )
}

export default HeroNL
