const IMAGES = {
    HTML5: require('../images/HTML5-logo.png'),
    CSS: require('../images/CSS-logo.png'),
    Javascript: require('../images/JavaScript-logo.png'),
    Python: require('../images/Python-logo.png'),
    Bootstrap: require('../images/Bootstrap-logo.png'),
    Flask: require('../images/Flask-logo.png'),
    Django: require('../images/Django-logo.png'),
    MongoDB: require('../images/MongoDB-logo.png'),
    React: require('../images/React-logo.png'),
    Heroku: require('../images/Heroku-logo.png'),
    Java: require('../images/java-logo.png'),
}

export default IMAGES;