import React from 'react';

const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <div id='footer'>
            <div className="socials">
                <a href="https://www.linkedin.com/in/tascosilva" target="_blank"
                    rel="noreferrer">
                    <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="https://github.com/chizzletaz" target="_blank"
                    rel="noreferrer">
                    <i class="fab fa-github"></i>
                </a>
            </div>
            <div className="copyright">
                <p>Copyright &copy; Tasco Silva {year}</p>
            </div>
        </div>
    )
}

export default Footer
