import React from 'react';

const About = () => {
    return (
        <div id='about'>
            <h1>About me</h1>
            <p>
                I’ve always been interested in technology and the last few years I've looked into coding. My interest and enthousiasm herein
                solidified my choice to further educate myself and start the Full Stack Development Course 
                at <a href="https://codeinstitute.net" target="_blank" rel="noreferrer">Code Institute</a>.
                In October 2021 I got my diploma for this.
            </p>
            <p>
                Being a musician, making and teaching music remains a passion.
                But I would like to make the switch to an environment where I can be part of a development team and
                expand my knowledge and skills as a developer.
            </p>
        </div>
    )
}

export default About
