import React from 'react';

const AboutNL = () => {
    return (
        <div id='about'>
            <h1>Over mij</h1>
            <p>
                Ik ben altijd al geïnteresseerd geweest in technologie en heb mij de laatste jaren verdiept in coderen.
                Mijn interesse en enthousiasme hierin heeft mij er toe gezet om te beginnen met de
                Full Stack Development opleiding bij <a href="https://codeinstitute.net" target="_blank" rel="noopener">Code Institute</a>.
                In oktober 2021 heb ik hiervoor mijn diploma behaald.
            </p>
            <p> Musicus zijn, muziek maken en muziek onderwijzen blijft een passie.
                Maar nu wil ik ook graag de stap maken naar een omgeving waar ik onderdeel uit kan maken van een ontwikkelteam
                om daar mijn kennis en vaardigheden uit te breiden en te delen.</p>
        </div>
    )
}

export default AboutNL
